.root {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}
.item {
  background-color: #62727b57;
  margin: 5px;
  padding: 5px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  border-color: #5e92f3;
  border-radius: 5px;
}
.item:hover {
  background-color: #4a7b9757;
}
button {
  cursor: pointer;
}

.goback {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}
.goback:hover {
  background-color: #5e92f379;
}
